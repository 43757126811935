/**
 * @author Abdul Rahim M - abdul@au79consulting.com
 * @date 14-06-2024
 * @description This is the Project Configurator Page for the Application and This page is  Update Project Status of the project
 */
//Importing the required components/functions from the libraries

import React, { useEffect, useState } from "react";
import CustomUpdateDialog from "../../components/dialog-boxes/CustomUpdateDialog";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import SelectTextField from "../../components/text-fields/SelectTextField";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import projectConfigAPI from "../../redux/actions/projectConfigAPI";
import settingsApis from "../../redux/actions/settings";

function UpdateStatus({ openStatus, boxes, setOpenStatus }) {
  const projectStatus = {
    100: "ACTIVE_PRE_SOP",
    200: "ACTIVE_POST_SOP",
    300: "DE_ACTIVATED",
    500: "COMPLETED",
  };

  const projectStatusByName = {
    ACTIVE_PRE_SOP: 100,
    ACTIVE_POST_SOP: 200,
    DE_ACTIVATED: 300,
    COMPLETED: 500,
  };

  //1.Redux
  const dispatch = useDispatch();
  const projectStatusDetails = useSelector(
    (state) => state.settingsReducer?.metaData?.project_status
  );
  const projectStatusList = Object.keys(projectStatusDetails);
  // console.log("projectStatusList", projectStatusList);
  //2.React useState
  const [selectPS, setSelectPS] = useState("");
  const [projectData, setProjectData] = useState([]);

  //3.React useEffect

  useEffect(() => {
    dispatch(HandleApiActions({ ...settingsApis.get_app_meta_data }));
  }, []);
  useEffect(() => {
    let projectStatusData = projectStatusList?.map((el, idx) => {
      return {
        value: projectStatusByName[el],
        label: formatString(el),
      };
    });
    // console.log("projectStatusData", projectStatusData);
    setProjectData(projectStatusData);
  }, [projectStatusDetails]);

  useEffect(() => {
    dispatch(HandleApiActions({ ...projectConfigAPI.get_project }));
  }, [boxes]);

  //4.Functions

  function formatString(str) {
    let result = str.charAt(0).toUpperCase() + str.slice(1);

    result = result
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return result;
  }
  //Function Change Edit Project Status
  const handleClickEditStatus = (index) => {
    const reqObj = {
      project_id: parseInt(index?.project_id),
      status: selectPS,
      current_time: moment().format(),
    };

    dispatch(
      HandleApiActions({
        ...projectConfigAPI.update_project_status,
        params: reqObj,
        show_toast: true,
      })
    );

    setOpenStatus(false);
  };

  useEffect(() => {
    setSelectPS(boxes?.status);
  }, []);

  return (
    <div>
      <CustomUpdateDialog
        open={openStatus}
        onClose={() => setOpenStatus(false)}
        dialogTitle={"Update Status"}
        dialogContent={
          <Stack spacing={2}>
            <Box sx={{ marginTop: "10px" }}>
              <SelectTextField
                required
                size={"small"}
                label={"Project Status"}
                optionsList={projectData}
                selectedValue={selectPS}
                setSelectedValue={setSelectPS}
                variant={"standard"}
                sltFldStyle={{
                  width: "200px",
                }}
              />
            </Box>
          </Stack>
        }
        onConfirm={() => handleClickEditStatus(boxes)}
      />
    </div>
  );
}

export default UpdateStatus;
