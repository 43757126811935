/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 11-06-2024
 * @description This is the Virtual Labcar Page for the Application
 */

//Importing the required components/functions from the libraries
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchSelectTextField } from "../../components";
import VlcMindMap from "./MindMap";
import VlcCarCard from "./CarCard";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import VLcLinkSWModules from "./LinkSWModules";
import {
  HandleApiActions,
  labCar,
  profileActions,
  userManagementApis,
} from "../../redux/actions";
import customConsole from "../../config/customConsole";
import VlcEEComponents from "./EEComponents";
import VlcZonalMindMap from "./ZonalMindMap";
import appTheme from "../../assets/AppTheme/appTheme";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 19-07-2024
 * @description : Main lab car function
 * @param : none
 * @return : The rendered VLC page.
 */
function LabCar() {
  const dispatch = useDispatch();

  /* useSelectors */
  const { projectDetails } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  /* Project-related state variables */
  const [projectOptionsList, setProjectOptionsList] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState(null);

  /* State for handling no projects found message */
  const [showNoProjectsFound, setShowNoProjectsFound] = useState(false);

  /* useEffects */

  useEffect(() => {
    // Get the project details
    dispatch(HandleApiActions({ ...labCar.get_project_details }));
    // Get the roles list
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_roles_list,
        params: {
          page_number: 1,
          page_size: 100,
          sort_column: "created_at",
          sort_order: "ASC",
        },
        show_toast: false,
      })
    );
    // Get the profile details
    dispatch(HandleApiActions({ ...profileActions.get_profile_details }));
  }, []);

  useEffect(() => {
    /* One second delay for the show no project */
    if (projectDetails.length === 0) {
      const timer = setTimeout(() => {
        setShowNoProjectsFound(true);
      }, 1000);
      // Clean up the timer if the component unmounts
      return () => clearTimeout(timer);
    } else {
      setShowNoProjectsFound(false);
    }
  }, []);

  useEffect(() => {
    /* Update project options list and select the first project by default */
    if (projectDetails) {
      let filteredProjectOptionsList = projectDetails?.map((el) => {
        return {
          value: el?.project_id,
          label: el?.name,
        };
      });
      setProjectOptionsList(filteredProjectOptionsList);

      /* Select first project by default */
      if (projectDetails[0]?.project_id && projectDetails[0]?.name) {
        setSelectedProject({
          value: projectDetails[0]?.project_id,
          label: projectDetails[0]?.name,
        });
      }
    }
  }, [projectDetails]);

  useEffect(() => {
    /* Update selected project details based on the selected project */
    if (selectedProject) {
      let filteredSelectedProjectDetails = projectDetails?.find(
        (el) => el?.project_id == selectedProject?.value
      );

      setSelectedProjectDetails(filteredSelectedProjectDetails);
    }
  }, [selectedProject]);

  useEffect(() => {
    customConsole("projectDetails: " + projectDetails);
    customConsole("selectedProject: " + selectedProject);
    customConsole("selectedProjectDetails: " + selectedProjectDetails);
  }, [selectedProjectDetails]);

  return (
    <Box>
      {projectDetails.length > 0 ? (
        // Project page
        <LabCarMain
          projectOptionsList={projectOptionsList}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          selectedProjectDetails={selectedProjectDetails}
        />
      ) : (
        // No project found
        showNoProjectsFound && (
          <Box sx={styles.noPFContainer}>
            <Typography variant="h6" sx={styles.noPFTxt}>
              No Projects Found
            </Typography>
          </Box>
        )
      )}
    </Box>
  );
}

export default LabCar;

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 19-07-2024
 * @description : Displays the vlc details.
 * @param : none
 * @return : The rendered VLC page.
 */
function LabCarMain({
  projectOptionsList,
  selectedProject,
  setSelectedProject,
  selectedProjectDetails,
}) {
  const dispatch = useDispatch();

  /* Extracting EE components list from the Redux store */
  const { eeComponentsList } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  /* State for handling the software modules screen */
  const [isLinkSoftwareModulesScreen, setIsLinkSoftwareModulesScreen] =
    useState(false);

  /* Car Card */
  const [selectedView, setSelectedView] = useState("CAR");

  /* Zones */
  const [availableZones, setAvailableZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedZoneDetails, setSelectedZoneDetails] = useState(null);

  /* Zone - EE */
  const [zoneEEList, setZoneEEList] = useState([]);
  /* Centralized - EE */
  const [centralizedEEList, setCentralizedEEList] = useState([]);

  /* Zone - EE Count */
  const [zoneEECountList, setZoneEECountList] = useState([]);

  /* Project - EE Count */
  const [projectEECount, setProjectEECount] = useState(0);

  /* useEffects */
  useEffect(() => {
    /* Make to default state when the project is changed */
    setIsLinkSoftwareModulesScreen(false);
    // setSelectedView("CAR");
    setAvailableZones([]);
    setSelectedZone(null);
    setSelectedZoneDetails(null);
    setZoneEEList([]);
    setCentralizedEEList([]);
    setZoneEECountList([]);
    setProjectEECount(0);
  }, [selectedProjectDetails]);

  const getZoneEECountList = async (projectId) => {
    const resp = await dispatch(
      HandleApiActions({
        ...labCar.get_ee_components_count,
        params: {
          project_id: projectId,
        },
        show_toast: false,
      })
    );

    if (resp.error) {
      setZoneEECountList([]);
    } else {
      if (resp?.data?.length > 0) {
        // Set the project total ee count
        setProjectEECount(resp?.data?.length || 0);

        const components = resp?.data;
        const counts = components?.reduce((acc, component) => {
          const position = component?.zone_details?.position;
          if (!acc[position]) {
            acc[position] = 0;
          }
          acc[position]++;
          return acc;
        }, {});

        const filteredZoneEECountList = Object?.entries(counts)?.map(
          ([zone_position, ee_components_count]) => ({
            zone_position,
            ee_components_count,
          })
        );
        if (filteredZoneEECountList?.length > 0) {
          setZoneEECountList(filteredZoneEECountList);
        } else {
          setZoneEECountList([]);
        }
      }
    }
  };

  useEffect(() => {
    /* Zones */
    if (selectedProjectDetails && selectedProjectDetails?.is_zonal) {
      let filteredAvailableZones = selectedProjectDetails?.zones?.map((el) => {
        return {
          zone_position: el.position,
          position_name: el.zone_name,
        };
      });
      setAvailableZones(filteredAvailableZones);
    }

    if (
      selectedProjectDetails &&
      // selectedProjectDetails?.is_zonal &&
      selectedProjectDetails?.project_id
    ) {
      getZoneEECountList(selectedProjectDetails?.project_id);
    }
  }, [selectedProjectDetails]);

  useEffect(() => {
    /* Update selected zone details based on the selected zone */
    if (
      selectedZone &&
      selectedZone?.zone_position_name &&
      selectedProjectDetails &&
      selectedProjectDetails?.zones
    ) {
      let filteredSelectedZoneDetails = selectedProjectDetails?.zones?.find(
        (el) => el.zone_name == selectedZone?.zone_position_name
      );
      setSelectedZoneDetails(filteredSelectedZoneDetails);
    }
  }, [selectedZone]);

  const getEEComponentsList = () => {
    /* Fetch EE components based on the selected project and zone details */
    if (
      (selectedZoneDetails &&
        selectedZoneDetails.sequence &&
        selectedProjectDetails &&
        selectedProjectDetails?.project_id) ||
      (selectedProjectDetails &&
        selectedProjectDetails?.project_id &&
        !selectedProjectDetails.is_zonal)
    ) {
      dispatch(
        HandleApiActions({
          ...labCar.get_ee_components,
          params: {
            project_id: selectedProjectDetails?.project_id,
            ...(selectedProjectDetails.is_zonal && {
              zone_sequence: selectedZoneDetails.sequence,
            }),
          },
          show_toast: false,
        })
      );
    }
  };

  useEffect(() => {
    getEEComponentsList();
  }, [selectedZoneDetails, selectedProjectDetails]);

  useEffect(() => {
    /* Update EE components list based on the fetched EE components */
    if (eeComponentsList) {
      if (selectedProjectDetails && selectedProjectDetails.is_zonal) {
        setZoneEEList(eeComponentsList);
      } else {
        setCentralizedEEList(eeComponentsList);
      }
    }
  }, [eeComponentsList]);

  useEffect(() => {
    customConsole("selectedZoneDetails: " + selectedZoneDetails);
    customConsole("selectedZone: " + selectedZone);
    customConsole("selectedProjectDetails: " + selectedProjectDetails);
    customConsole("zoneEEList " + zoneEEList);
    customConsole({ zoneEECountList });
  }, [selectedZoneDetails, zoneEEList, zoneEECountList]);

  /* -------------- Handle Zone EE functions -------------- */
  /* Add a new EE component */
  const handleZoneEEAdd = ({
    assembly_number,
    supplier_name,
    version,
    summary,
    description,
  }) => {
    if (
      (selectedZoneDetails &&
        selectedZoneDetails.sequence &&
        selectedProjectDetails &&
        selectedProjectDetails?.project_id) ||
      (selectedProjectDetails &&
        selectedProjectDetails?.project_id &&
        !selectedProjectDetails.is_zonal)
    ) {
      let currentTime = new Date().toISOString();

      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        ...(selectedProjectDetails.is_zonal && {
          zone_sequence: selectedZoneDetails.sequence,
        }),
        assembly_number,
        supplier_name,
        version,
        ...(summary && { summary }),
        description,
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...labCar.add_ee_component,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            ...(selectedProjectDetails.is_zonal && {
              zone_sequence: selectedZoneDetails.sequence,
            }),
            assembly_number,
            supplier_name,
            version,
            ...(summary && { summary }),
            description,
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getEEComponentsList();
        getZoneEECountList(selectedProjectDetails?.project_id);
      });
    }
  };

  /* Update an existing EE component */
  const handleZoneEEUpdate = ({ ee_id, version }) => {
    if (selectedProjectDetails && selectedProjectDetails?.project_id) {
      let currentTime = new Date().toISOString();

      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        ee_id,
        version,
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...labCar.update_ee_component,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            ee_id,
            version,
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getEEComponentsList();
      });
    }
  };

  /* Remove an EE component */
  const handleZoneEEReplace = ({
    ee_id,
    assembly_number,
    supplier_name,
    version,
    summary,
    description,
  }) => {
    if (selectedProjectDetails && selectedProjectDetails?.project_id) {
      let currentTime = new Date().toISOString();

      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        ee_id,
        assembly_number,
        supplier_name,
        version,
        ...(summary && { summary }),
        description,
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...labCar.replace_ee_component,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            ee_id,
            assembly_number,
            supplier_name,
            version,
            ...(summary && { summary }),
            description,
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getEEComponentsList();
      });
    }
  };

  return (
    <Box sx={styles.mainContainer}>
      {/* Search Select Text Field, Link Software Modules Button */}
      <Box sx={styles.topContainer}>
        {selectedProject && !isLinkSoftwareModulesScreen && (
          <Box sx={styles.linkBtnContainer}>
            <Button
              sx={styles.linkBtn}
              color="primary"
              variant="contained"
              onClick={() => setIsLinkSoftwareModulesScreen(true)}
            >
              Link Software Modules
            </Button>
          </Box>
        )}
        {!isLinkSoftwareModulesScreen && (
          <Box sx={styles.searchContainer}>
            <SearchSelectTextField
              size={"small"}
              label={"Search Project"}
              placeholder={"Search Project"}
              noOptionsText={"No project found"}
              optionsList={projectOptionsList}
              selectedValue={selectedProject}
              setSelectedValue={setSelectedProject}
              txtFldStyle={styles.searchSelectTextField}
            />
          </Box>
        )}
        {selectedProject && !isLinkSoftwareModulesScreen && (
          // Buttons to select the car or mind map
          <Box sx={styles.switchBtns}>
            {selectedView === "MAP" ? (
              <Button
                sx={{ ...appTheme.commonBtnStyle, mr: 2.5 }}
                onClick={() => {
                  window.open(
                    "https://app.mural.co/t/mbvans9255/m/mbvans9255/1724825550085/c41043c3786e1450c9aa41dc9e2c8b486138678f?sender=9afb4ad3-e2dd-45ef-bfdd-63bae43db612",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                UPCOMING VIEW
              </Button>
            ) : null}
            <Button
              sx={
                selectedView === "CAR"
                  ? { ...appTheme.commonBtnStyle }
                  : {
                      ...appTheme.commonBtnStyle,
                      backgroundColor: "#b7d0b7",
                      color: "black",
                      border: "1px solid black",
                    }
              }
              onClick={() => setSelectedView("CAR")}
            >
              CAR VIEW
            </Button>
            <Button
              sx={
                selectedView === "MAP"
                  ? { ...appTheme.commonBtnStyle, ml: 1 }
                  : {
                      ...appTheme.commonBtnStyle,
                      ml: 1,
                      backgroundColor: "#b7d0b7",
                      color: "black",
                      border: "1px solid black",
                    }
              }
              onClick={() => setSelectedView("MAP")}
            >
              MIND MAP
            </Button>
          </Box>
        )}
      </Box>

      {/* Vlc */}
      {selectedProject && (
        <Box>
          {selectedProjectDetails &&
          selectedProjectDetails?.project_id &&
          selectedProjectDetails?.zones &&
          selectedProjectDetails?.ee_ids &&
          // selectedProjectDetails?.name &&
          // selectedProjectDetails?.architecture_name &&
          // selectedProjectDetails?.trade_mark &&
          // selectedProjectDetails?.vehicle_platform &&
          isLinkSoftwareModulesScreen ? (
            // Link Software Modules Screen
            <VLcLinkSWModules
              selectedProjectId={selectedProjectDetails?.project_id}
              selectedProjectIsZonal={selectedProjectDetails?.is_zonal}
              selectedProjectZonesList={selectedProjectDetails?.zones}
              selectedProjectName={selectedProjectDetails?.name}
              selectedProjectArchitecture={
                selectedProjectDetails?.architecture_name
              }
              selectedProjectTradeMark={selectedProjectDetails?.trade_mark}
              selectedProjectVehiclePlatform={
                selectedProjectDetails?.vehicle_platform
              }
              selectedProjectTotalZones={selectedProjectDetails?.zones?.length}
              selectedProjectTotalEEComponents={
                projectEECount > selectedProjectDetails?.ee_ids?.length
                  ? projectEECount
                  : selectedProjectDetails?.ee_ids?.length
              }
              isLinkSoftwareModulesScreen={isLinkSoftwareModulesScreen}
              setSelectedView={setSelectedView}
              setIsLinkSoftwareModulesScreen={setIsLinkSoftwareModulesScreen}
            />
          ) : (
            <Box sx={styles.container}>
              <Paper elevation={6} sx={styles.prjDetailsContainer}>
                <Grid container>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
                      <span style={styles.txtBold}>Project: </span>
                      {selectedProjectDetails?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
                      <span style={styles.txtBold}>Architecture: </span>
                      {selectedProjectDetails?.architecture_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
                      <span style={styles.txtBold}>Trade Mark: </span>
                      {selectedProjectDetails?.trade_mark}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
                      <span style={styles.txtBold}>Vehicle Platform: </span>
                      {selectedProjectDetails?.vehicle_platform}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
                      <span style={styles.txtBold}>Zones: </span>
                      {selectedProjectDetails?.is_zonal
                        ? selectedProjectDetails?.zones?.length
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={styles.prjDetailsTxt}>
                      <span style={styles.txtBold}>EE Components: </span>
                      {projectEECount > selectedProjectDetails?.ee_ids?.length
                        ? projectEECount
                        : selectedProjectDetails?.ee_ids?.length}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>

              {selectedView === "CAR" ? (
                // Show the car view
                <Box sx={styles.carContainer}>
                  <Box sx={styles.carCardContainer}>
                    <VlcCarCard
                      availableZones={availableZones}
                      selectedComponent={selectedZone}
                      setSelectedComponent={setSelectedZone}
                      zoneEECountList={zoneEECountList}
                    />
                  </Box>

                  {/* EE component */}
                  {((selectedZone && selectedZoneDetails?.zone_name) ||
                    (selectedProjectDetails &&
                      !selectedProjectDetails.is_zonal)) && (
                    <Box sx={styles.eeCompContainer}>
                      <Box>
                        <VlcEEComponents
                          heading={
                            selectedProjectDetails.is_zonal
                              ? selectedZoneDetails?.zone_name
                              : "Centralized"
                          }
                          eEComponentsList={
                            selectedProjectDetails.is_zonal
                              ? zoneEEList
                              : centralizedEEList
                          }
                          handleEEComponentAdd={handleZoneEEAdd}
                          handleEEComponentUpdate={handleZoneEEUpdate}
                          handleEEComponentReplace={handleZoneEEReplace}
                          selectedProjectDetails={selectedProjectDetails}
                          selectedZone={selectedZone}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              ) : (
                <>
                  {/* Show mind map */}
                  {selectedProjectDetails &&
                  selectedProjectDetails?.project_id &&
                  !selectedProjectDetails.is_zonal ? (
                    <VlcMindMap
                      selectedProjectId={selectedProjectDetails?.project_id}
                    />
                  ) : (
                    <VlcZonalMindMap
                      selectedProjectId={selectedProjectDetails?.project_id}
                    />
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

// Styles
const styles = {
  mainContainer: {
    overflow: "auto",
  },
  topContainer: {
    mt: 1,
    mx: 2,
    flexDirection: { xs: "column", md: "row" },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchContainer: {
    textAlign: "start",
    mt: { xs: 2, md: 0 },
  },
  switchBtns: {
    mt: { xs: 2, md: 0 },
  },
  linkBtnContainer: {
    mt: { xs: 2, md: 0 },
  },
  linkBtn: {
    // mt: { xs: 2, sm: 0 },
    // ml: { sm: 4 },
    // width: "215px",
    ...appTheme.commonBtnStyle,
  },
  container: {
    margin: "20px",
    marginBottom: "100px",
    display: "flex",
    flexDirection: "column",
  },
  detailsTxt: {
    flex: 1,
    textAlign: "center",
    // fontWeight: "bold",
  },
  carContainer: {
    width: "100%",
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    justifyContent: { xs: "center", lg: "space-evenly" },
    alignItems: { xs: "center", lg: "space-evenly" },
    overflowX: "auto",
    px: 2,
    pb: 4,
  },
  carCardContainer: {
    width: "520px",
  },
  eeCompContainer: {
    display: "flex",
    justifyContent: "center",
  },
  noPFContainer: {
    mt: 12,
  },
  noPFTxt: {
    mb: 1,
  },
  searchSelectTextField: {
    width: "215px",
    backgroundColor: "white",
  },
  prjDetailsContainer: {
    px: 2,
    py: 1,
    mb: 2,
  },
  prjDetailsTxt: {
    textAlign: "start",
    pl: 2,
  },
  txtBold: {
    fontWeight: "bold",
  },
};
