import React from "react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import customConsole from "../../config/customConsole";

function MultiSelectTextField2({
  size,
  label,
  optionsList,
  selectedValues,
  setSelectedValues,
  sltFldStyle,
}) {
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 220,
      },
    },
  };

  // Handles change events for the multi-select dropdown.
  const handleChange = (event) => {
    customConsole(event.target.value);
    const {
      target: { value },
    } = event;
    setSelectedValues(
      // If the value is a string, split it into an array; otherwise, use the value directly
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <FormControl size={size} sx={sltFldStyle}>
      <InputLabel>{label}</InputLabel>
      <Select
        variant="standard"
        multiple
        value={selectedValues}
        onChange={handleChange}
        //input={<OutlinedInput label={label} />}
        renderValue={(selected) =>
          selected
            .map((id) => {
              const option = optionsList.find((opt) => opt.id === id);
              return option ? option.abbreviation : "";
            })
            .join(", ")
        }
        MenuProps={menuProps}
      >
        {optionsList?.map((data) => (
          <MenuItem key={data?.id} value={data?.id}>
            <Checkbox checked={selectedValues.indexOf(data?.id) > -1} />
            <ListItemText primary={`${data?.name}-${data?.abbreviation}`} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelectTextField2;
