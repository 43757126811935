import React, { useEffect, useState } from "react";
import CustomUpdateDialog from "../../components/dialog-boxes/CustomUpdateDialog";
import { Box, Stack } from "@mui/material";
import SelectTextField from "../../components/text-fields/SelectTextField";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import settingsApis from "../../redux/actions/settings";
import moment from "moment";
import projectConfigAPI from "../../redux/actions/projectConfigAPI";

function UpdateVehicleStatus({
  openVehicleStatus,
  boxes,
  setOpenVehicleStatus,
}) {
  const dispatch = useDispatch();
  const vehicleStatusDetails = useSelector(
    (state) => state.settingsReducer?.metaData?.vehicle_status
  );

  //2.React useState
  const [selectPS, setSelectPS] = useState("");
  const [projectData, setProjectData] = useState([]);

  //3.React useEffect

  useEffect(() => {
    dispatch(HandleApiActions({ ...settingsApis.get_app_meta_data }));
  }, []);
  useEffect(() => {
    let vehicleStatusData = vehicleStatusDetails?.map((el, idx) => {
      return {
        value: el,
        label: formatString(el),
      };
    });
    // console.log("projectStatusData", vehicleStatusData);
    setProjectData(vehicleStatusData);
  }, [vehicleStatusDetails]);

  //4.Functions

  function formatString(str) {
    let result = str.charAt(0).toUpperCase() + str.slice(1);

    result = result
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return result;
  }

  const handleClickEditStatus = (index) => {
    const reqObj = {
      project_id: parseInt(index?.project_id),
      vehicle_status: selectPS,
      current_time: moment().format(),
    };

    dispatch(
      HandleApiActions({
        ...projectConfigAPI.update_project,
        params: reqObj,
        show_toast: true,
      })
    );

    setOpenVehicleStatus(false);
  };
  return (
    <div>
      <CustomUpdateDialog
        open={openVehicleStatus}
        onClose={() => setOpenVehicleStatus(false)}
        dialogTitle={"Update Vehicle Status"}
        dialogContent={
          <Stack spacing={2}>
            <Box sx={{ marginTop: "10px" }}>
              <SelectTextField
                required
                size={"small"}
                label={"Vehicle Status"}
                optionsList={projectData}
                selectedValue={selectPS}
                setSelectedValue={setSelectPS}
                variant={"standard"}
                sltFldStyle={{
                  width: "200px",
                }}
              />
            </Box>
          </Stack>
        }
        onConfirm={() => handleClickEditStatus(boxes)}
      />
    </div>
  );
}

export default UpdateVehicleStatus;
