/**
 * @author Abdul Rahim M - abdul@au79consulting.com
 * @date 11-06-2024
 * @description This is the Project Configurator Page for the Application and it is the card
 */
//Importing the required components/functions from the libraries

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { GetSingleImages } from "../../redux/actions/actionHandler";
import appTheme from "../../assets/AppTheme/appTheme";
import customConsole from "../../config/customConsole";
import ImageBox from "./ImageBox";

function FrontBox({ index, handleClickBox, boxes, profileDetails }) {
  const projectStatus = {
    100: (
      <Chip
        sx={{
          boxShadow: "3",
          fontWeight: "bold",
          borderRadius: "0px 15px 0px 15px",
          backgroundColor: "#c8e8fa",
          color: "black",
        }}
        label={"Active Pre SOP"}
        size="medium"
      />
    ),
    200: (
      <Chip
        sx={{
          boxShadow: "3",
          fontWeight: "bold",
          borderRadius: "0px 15px 0px 15px",
          backgroundColor: "#f7f6cd",
          color: "black",
        }}
        label={"Active Post SOP"}
        size="medium"
      />
    ),
    300: (
      <Chip
        sx={{
          boxShadow: "3",
          fontWeight: "bold",
          borderRadius: "0px 15px 0px 15px",
          backgroundColor: "red",
          color: "white",
        }}
        label={"Deactivated"}
        size="medium"
      />
    ),
    500: (
      <Chip
        sx={{
          boxShadow: "3",
          fontWeight: "bold",
          borderRadius: "0px 15px 0px 15px",
          backgroundColor: "#9af5a2",
          color: "green",
        }}
        label={"Completed"}
        size="medium"
      />
    ),
  };
  //1.React useState
  const [userDetails, setUserDetails] = useState([]);

  //2.React useEffect
  useEffect(() => {
    setUserDetails(profileDetails?.profile);
  }, [userDetails]);

  const getStatusName = (statusCode) => {
    return projectStatus[statusCode] || "Unknown Status";
  };
  function capitalizeFirstLetter(str) {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const truncateAddress = (address, maxLength = 10) => {
    if (!address) return "";
    return address.length > maxLength
      ? address.slice(0, maxLength) + "..."
      : address;
  };

  return (
    <div key={index} style={{ marginLeft: "20px", marginRight: "20px" }}>
      <Paper elevation={24} sx={{ width: "100%" }}>
        <Box sx={styled.box}>
          <Box sx={styled.secBox}>
            <Typography sx={styled.typo}>{boxes?.name}</Typography>
            <Typography sx={styled.typo1}>
              {capitalizeFirstLetter(boxes?.architecture_name)}
            </Typography>
          </Box>

          <Box>
            <Typography color="white">
              {getStatusName(boxes?.status)}
            </Typography>
          </Box>
        </Box>
        <Box sx={styled.trademarkBox}>
          <Typography sx={styled.trademarkTypo}>Trademark</Typography>
          <Typography sx={styled.trademarkTypo1}>
            {boxes?.trade_mark === "" ? boxes?.name : boxes?.trade_mark}
          </Typography>
        </Box>

        <Box>
          <Box sx={styled.trademarkBox}>
            <Typography sx={styled.trademarkTypo}>Vehicle Platform</Typography>
            <Typography textAlign="start" fontWeight="bold">
              {boxes?.vehicle_platform}
            </Typography>
          </Box>
        </Box>

        <Box sx={styled.vehicleClassBox}>
          <Typography sx={styled.vehicleClassTypo}>
            Vehicle Class Details
          </Typography>
          <Box sx={styled.vehicleClassSecBox}>
            <Box sx={styled.vehicleClassTrBox}>
              <Typography sx={styled.vehicleClassSecTypo}>
                {boxes?.vehicle_class_details[0]?.name}
              </Typography>
              <Typography sx={styled.vehicleClassSecTypoOne}>
                {boxes?.vehicle_class_details[0]?.class}-{" "}
                {boxes?.vehicle_class_details[0]?.sub_class}
              </Typography>
            </Box>
            {/* <Box sx={styled.imgBox}>
              <img alt="" src={image} style={{ width: 46, height: 46 }} />
            </Box> */}
            <ImageBox boxes={boxes} />
          </Box>
        </Box>

        <Box sx={styled.addressBox}>
          <Box sx={styled.addressBoxSec}>
            <Typography sx={styled.addressName}>Name</Typography>
            <Typography sx={styled.addressName}>
              {boxes?.mft_details?.name}
            </Typography>
          </Box>
          <Box sx={styled.addressBoxSec}>
            <Typography fontSize="11px">Address</Typography>
            <Typography fontSize="11px">
              {truncateAddress(boxes?.mft_details?.address, 30)}
            </Typography>
          </Box>
        </Box>

        <Box sx={styled.createdBox}>
          <Box sx={styled.createdSecBox}>
            <Typography sx={styled.createdBoxTypo}>Created :</Typography>
            <Typography sx={{ fontSize: "13px" }}>
              {boxes?.created_at}
            </Typography>
          </Box>
          <Box display="flex" ml="30px">
            <Button
              endIcon={<DoubleArrowIcon />}
              size="small"
              sx={{
                ...appTheme.commonBtnStyle,
              }}
              variant="outlined"
              onClick={() => handleClickBox(boxes?.project_id)}
            >
              View
            </Button>
          </Box>
        </Box>
      </Paper>
    </div>
  );
}

export default FrontBox;

const styled = {
  box: {
    display: "flex",
    justifyContent: "space-between",

    alignItems: "center",
    padding: "10px",
    backgroundColor: "#302e33",
    borderRadius: "5px 5px 0px 0px ",
    width: { md: "400px" },
  },
  secBox: { padding: "5px", background: "wheatsmoke" },
  typo: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "200px",
    display: "block",
    textAlign: "start",
    height: "20px",
    fontWeight: "bold",
    color: "white",
  },
  typo1: {
    textAlign: "start",
    fontSize: "12px",
    fontWeight: "bold",
    fontStyle: "normal",
    color: "#bcbdb7",
  },
  trademarkBox: {
    paddingRight: "5px",
    paddingLeft: "5px",
    width: "95%",
    height: "40px",
    ml: "10px",
    mt: "10px",
    mb: "10px",
    mr: "10px",
    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)",
    backgroundColor: "white",
    borderRadius: "5px",
  },
  trademarkTypo: {
    textAlign: "start",
    fontWeight: "bold",
    fontSize: "9px",
  },
  trademarkTypo1: {
    textAlign: "start",
    fontWeight: "bold",
  },
  vehicleClassBox: {
    paddingRight: "5px",
    paddingLeft: "5px",
    height: "auto",
    width: "95%",
    ml: "10px",
    mt: "10px",
    mb: "10px",
    backgroundColor: "white",
    borderRadius: "5px",
    mr: "10px",
    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)",
  },
  vehicleClassTypo: { textAlign: "start", fontWeight: "bold", fontSize: "9px" },
  vehicleClassSecBox: {
    display: "flex",
    justifyContent: "space-between",
    mt: "3px",
  },
  vehicleClassTrBox: { borderRadius: "5px", fontSize: "20px" },
  vehicleClassSecTypo: {
    fontSize: "13px",
    textAlign: "start",
    fontWeight: "bold",
  },
  vehicleClassSecTypoOne: {
    fontSize: "12px",
    textAlign: "start",
  },

  addressBox: {
    padding: "10px",
    ml: "10px",
    mt: "3px",
    mr: "10px",
    mb: "10px",
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)",
  },
  addressBoxSec: { display: "flex", justifyContent: "space-between" },
  addressName: { fontSize: "15px", fontWeight: "bold" },
  createdBox: {
    display: "flex",
    justifyContent: "space-between",
    mt: "5px",
    padding: "10px",
    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)",
  },
  createdSecBox: {
    display: "flex",
    mt: "5px",
  },
  createdBoxTypo: { fontWeight: "bold", fontSize: "13px", color: "green" },
};
