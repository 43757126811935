/**
 * @author Tahir Shaik - tahir@bookwater.com - +91-tahir@au79consulting.com
 * @date 11-06-2024
 * @description This is the Virtual Type Approval Page for the Application
 */

//Importing the required components/functions from the libraries
import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Links from "./Links/Links";
import Intersections from "./Intersections/Intersections";
import Tickets from "./Tickets/Tickets";
import { SearchSelectTextField } from "../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import labCar from "../../redux/actions/labCar";
import typeApprovalAPI from "../../redux/actions/typeApprovalAPI";

//Main Virtual Type Approval Page Function
function Vta() {
  const dispatch = useDispatch();

  /* useSelectors */
  const { projectDetails } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  const { eeComponentsList } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  const intersectionDetails = useSelector((state) => state.typeApprovalReducer);
  //useState to handle multiple states
  const [tabValue, setTabValue] = useState(0);
  const [projectOptionsList, setProjectOptionsList] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState(null);
  /* Zones */
  const [availableZones, setAvailableZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedZoneDetails, setSelectedZoneDetails] = useState(null);
  /* Zone - EE */
  const [zoneEEList, setZoneEEList] = useState([]);
  /* Centralized - EE */
  const [centralizedEEList, setCentralizedEEList] = useState([]);
  const [showNoProjectsFound, setShowNoProjectsFound] = useState(false);
  const [intersectionDetailsNew, setIntersectionDetailsNew] = useState([]);
  const [linkTab, setLinkTab] = useState(false);
  //Effect to handle sideEffects

  useEffect(() => {
    setIntersectionDetailsNew(intersectionDetails?.cTypeApproval);
  }, [intersectionDetails]);
  useEffect(() => {
    dispatch(HandleApiActions({ ...labCar.get_project_details }));
  }, []);
  useEffect(() => {
    if (projectDetails) {
      let filteredProjectOptionsList = projectDetails?.map((el) => {
        return {
          value: el?.project_id,
          label: el.name,
        };
      });
      setProjectOptionsList(filteredProjectOptionsList);
    }
  }, [projectDetails]);

  useEffect(() => {
    if (selectedProject) {
      let filteredSelectedProjectDetails = projectDetails?.find(
        (el) => el?.project_id === selectedProject?.value
      );
      setSelectedProjectDetails(filteredSelectedProjectDetails);
    }
  }, [selectedProject]);

  // useEffect(() => {
  //   console.log("projectDetails:", projectDetails);
  //   console.log("selectedProject:", selectedProject);
  //   console.log("selectedProjectDetails:", selectedProjectDetails);
  // }, [selectedProjectDetails]);

  useEffect(() => {
    setAvailableZones([]);
    setSelectedZone(null);
    setSelectedZoneDetails(null);
    setZoneEEList([]);
    setCentralizedEEList([]);
  }, [selectedProjectDetails]);

  useEffect(() => {
    if (selectedProjectDetails && selectedProjectDetails?.is_zonal) {
      let filteredAvailableZones = selectedProjectDetails?.zones?.map((el) => {
        return {
          zone_position: el.position,
          position_name: el.zone_name,
        };
      });
      setAvailableZones(filteredAvailableZones);
    }
  }, [selectedProjectDetails]);

  useEffect(() => {
    if (
      selectedZone &&
      selectedZone?.zone_position_name &&
      selectedProjectDetails &&
      selectedProjectDetails?.zones
    ) {
      let filteredSelectedZoneDetails = selectedProjectDetails?.zones?.find(
        (el) => el.zone_name == selectedZone?.zone_position_name
      );
      setSelectedZoneDetails(filteredSelectedZoneDetails);
    }
  }, [selectedZone]);

  useEffect(() => {
    /* Project */
    if (projectDetails) {
      let filteredProjectOptionsList = projectDetails?.map((el) => {
        return {
          value: el?.project_id,
          label: el.name,
        };
      });
      setProjectOptionsList(filteredProjectOptionsList);
    }
  }, [projectDetails]);

  useEffect(() => {
    /* One second delay for the show no project */
    if (projectDetails.length === 0) {
      const timer = setTimeout(() => {
        setShowNoProjectsFound(true);
      }, 1000);
      // Clean up the timer if the component unmounts
      return () => clearTimeout(timer);
    } else {
      setShowNoProjectsFound(false);
    }
  }, []);

  useEffect(() => {
    getEEComponentsList();
  }, [selectedZoneDetails, selectedProjectDetails]);

  useEffect(() => {
    if (eeComponentsList) {
      if (selectedProjectDetails && selectedProjectDetails.is_zonal) {
        setZoneEEList(eeComponentsList);
      } else {
        setCentralizedEEList(eeComponentsList);
      }
    }
  }, [eeComponentsList]);

  // useEffect(() => {
  //   console.log("selectedZoneDetails:", selectedZoneDetails);
  //   console.log("selectedZone:", selectedZone);
  //   console.log("selectedProjectDetails:", selectedProjectDetails);
  //   console.log("zoneEEList", zoneEEList);
  // }, [selectedZoneDetails, zoneEEList]);

  //Functions to handle Dispatch
  const getEEComponentsList = () => {
    if (
      (selectedZoneDetails &&
        selectedZoneDetails.sequence &&
        selectedProjectDetails &&
        selectedProjectDetails?.project_id) ||
      (selectedProjectDetails &&
        selectedProjectDetails?.project_id &&
        !selectedProjectDetails.is_zonal)
    ) {
      dispatch(
        HandleApiActions({
          ...labCar.get_ee_components,
          params: {
            project_id: selectedProjectDetails?.project_id,
            ...(selectedProjectDetails.is_zonal && {
              zone_sequence: selectedZoneDetails.sequence,
            }),
          },
          show_toast: false,
        })
      );
    }
  };

  //Function to handle Tab Change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0 || newValue === 2) {
      setLinkTab(false);
      setIntersectionDetailsNew([]);
    }
  };

  useEffect(() => {
    if (tabValue === 0) {
      dispatch({
        type: typeApprovalAPI.get_add_intersection.action_type,
        payload: { error: true, msg: "", data: { intersections: [] } },
      });
    }
  }, [tabValue]);

  return (
    <Box>
      <Box sx={styles.box1}>
        <Box sx={styles.box2}>
          {/* <Box>
            <SearchSelectTextField
              size={"small"}
              label={"Search Project"}
              placeholder={"Search Project"}
              noOptionsText={"No project found"}
              optionsList={projectOptionsList}
              selectedValue={selectedProject}
              setSelectedValue={setSelectedProject}
              txtFldStyle={{
                mt: { sm: 2, md: 1 },
                width: "215px",
                backgroundColor: "white",
              }}
            />
          </Box> */}
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab
              sx={{
                "&.Mui-selected": {
                  fontWeight: 600,
                  color: "black",
                },
              }}
              iconPosition="start"
              label="Link"
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                "&.Mui-selected": {
                  fontWeight: 600,

                  color: "black",
                },
              }}
              disabled={linkTab ? false : true}
              iconPosition="start"
              label="Intersections"
              {...a11yProps(1)}
            />
            <Tab
              sx={{
                "&.Mui-selected": {
                  fontWeight: 600,

                  color: "black",
                },
              }}
              label="Tickets"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>

        <Box>
          <CustomTabPanel value={tabValue} index={0}>
            <Links
              projectOptionsList={projectOptionsList}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              selectedProjectDetails={selectedProjectDetails}
              selectedProjectId={selectedProjectDetails?.project_id}
              selectedProjectIsZonal={selectedProjectDetails?.is_zonal}
              selectedProjectZonesList={selectedProjectDetails?.zones}
              setTabValue={setTabValue}
              setLinkTab={setLinkTab}
              tabValue={tabValue}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            {/* <Intersections
              projectOptionsList={projectOptionsList}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              selectedProjectDetails={selectedProjectDetails}
            /> */}
            {/* <IntersectionsNew
              projectOptionsList={projectOptionsList}
              selectedProject={selectedProject}
              selectedProjectId={selectedProjectDetails?.project_id}
              setSelectedProject={setSelectedProject}
              selectedProjectDetails={selectedProjectDetails}
              selectedProjectIsZonal={selectedProjectDetails?.is_zonal}
              setTabValue={setTabValue}
              selectedProjectZonesList={selectedProjectDetails?.zones}
            /> */}
            {intersectionDetailsNew && intersectionDetailsNew?.length ? (
              <Intersections
                intersectionDetailsList={intersectionDetailsNew}
                handleTabChange={handleTabChange}
                tabValue={tabValue}
                setTabValue={setTabValue}
              />
            ) : (
              <Typography
                sx={{ fontWeight: "bold", color: "red", fontSize: "14px" }}
              >
                Data not found, please create the intersections!
              </Typography>
            )}

            {/* <h1>OK</h1> */}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>
            <Tickets
              projectOptionsList={projectOptionsList}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              selectedProjectDetails={selectedProjectDetails}
              selectedProjectId={selectedProjectDetails?.project_id}
            />
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
}

export default Vta;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//Styles for the Page
const styles = {
  box1: { width: "100%", position: "relative" },
  box2: {
    position: "sticky",
    top: 0,
    borderBottom: 1,
    borderColor: "divider",
    backgroundColor: "white",
    zIndex: 1000,
  },
};
