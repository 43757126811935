import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FirstScreen from "./FirstScreen";
import { useSelector } from "react-redux";

function CreateProject({ open, close }) {
  //1.Redux
  const profileDetails = useSelector((state) => state.profileReducer);

  return (
    <div>
      <Dialog fullWidth maxWidth="md" open={open}>
        <DialogTitle sx={styled.dialogTitle} id="customized-dialog-title">
          <Typography fontWeight="bold" variant="h5">
            Create Project
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => close(false)}
          sx={styled.iconButton}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box>
            <FirstScreen
              close={close}
              profileDetails={profileDetails}
              key="first-screen"
            />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CreateProject;

const styled = {
  dialogTitle: { m: 0, p: 2, boxShadow: "3" },
  iconButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "red",
  },
};
