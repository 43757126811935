/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 24-07-2024
 * @description : Component to add, update and replace software modules.
 */
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { TbReplace } from "react-icons/tb";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import {
  CustomAddDialog,
  CustomLogsDialog,
  CustomReplaceDialog,
  CustomTextFieldN,
  CustomUpdateDialog,
  MultiSelectTextFieldN,
} from "../../components";
import appTheme from "../../assets/AppTheme/appTheme";
import customConsole from "../../config/customConsole";
import { useDispatch } from "react-redux";
import { HandleApiActions, labCar } from "../../redux/actions";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 24-07-2024
 * @description : Show the software modules component
 * @param : heading, sWModulesList, handleSWModuleAdd, handleSWModuleUpdate, handleSWModuleReplace, setIsEEComponentSelected, setSelectedEEComponentDetails, selectedProjectDetails,
 * @return : The rendered software module component.
 */
function VlcSWModules({
  heading,
  sWModulesList,
  handleSWModuleAdd,
  handleSWModuleUpdate,
  handleSWModuleReplace,
  setIsEEComponentSelected,
  setSelectedEEComponentDetails,
  selectedProjectDetails,
  selectedEEComponentDetails,
}) {
  const dispatch = useDispatch();

  /* -------------- Dialogs -------------- */
  const [openSWModuleAddDialog, setOpenSWModuleAddDialog] = useState(false);
  const [openSWModuleUpdateDialog, setOpenSWModuleUpdateDialog] =
    useState(false);
  const [openSWModuleReplaceDialog, setOpenSWModuleReplaceDialog] =
    useState(false);
  const [openSWModuleChangeLogsDialog, setOpenSWModuleChangeLogsDialog] =
    useState(false);

  /* -------------- Selected sw module update/replace details  -------------- */
  const [selectedSWModuleUpdateDetails, setSelectedSWModuleUpdateDetails] =
    useState(null);
  const [selectedSWModuleReplaceDetails, setSelectedSWModuleReplaceDetails] =
    useState(null);

  /* -------------- Vehicle Functions-------------- */
  const [vfOptionsList, setVfOptionsList] = useState([]);

  /* -------------- SW Modules Change Logs -------------- */
  const [swChangeLogsList, setSWChangeLogsList] = useState([]);
  const [swLogsDialogData, setSWLogsDialogData] = useState({});

  /* -------------- Handle update/replace functions -------------- */
  const handleSelectedSWModuleUpdateDetails = (sWModule) => {
    setOpenSWModuleUpdateDialog(true);

    if (sWModule) {
      setSelectedSWModuleUpdateDetails(sWModule);
    }
  };

  const handleSelectedSWModuleReplaceDetails = (sWModule) => {
    setOpenSWModuleReplaceDialog(true);

    if (sWModule) {
      setSelectedSWModuleReplaceDetails(sWModule);
    }
  };

  /* Get software module change logs */
  const handleGetSWModuleChangeLogs = async ({ swm_id, pageNo, pageSize }) => {
    let apiDetails = labCar.get_sw_modules_change_logs;

    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedEEComponentDetails &&
      selectedEEComponentDetails?.ee_id
    ) {
      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        ee_id: Number(selectedEEComponentDetails?.ee_id),
        swm_id,
        page_number: pageNo,
        page_size: pageSize,
      });

      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            ee_id: Number(selectedEEComponentDetails?.ee_id),
            swm_id,
            page_number: pageNo,
            page_size: pageSize,
          },
          show_toast: false,
        })
      );
      if (resp.error) {
        setSWChangeLogsList([]);
      } else {
        setSWChangeLogsList(resp?.data);
      }
    }
  };

  /* Handle dialogs */
  const handleSWLogsDialogOpen = (data) => {
    setOpenSWModuleChangeLogsDialog(true);

    // handleGetSWModuleChangeLogs({
    //   ee_id: Number(data?.ee_id),
    //   swm_id: Number(data?.swm_id),
    // });

    setSWLogsDialogData(data);
  };
  const handleSWLogsDialogClose = () => {
    setSWLogsDialogData(null);
    setSWChangeLogsList([]);

    setOpenSWModuleChangeLogsDialog(false);
  };

  /* -------------- useEffects -------------- */
  useEffect(() => {
    if (
      selectedProjectDetails &&
      selectedProjectDetails?.vehicle_function_details
    ) {
      setVfOptionsList(selectedProjectDetails?.vehicle_function_details || []);
    }
  }, [selectedProjectDetails]);

  useEffect(() => {
    customConsole(
      "selectedSWModuleUpdateDetails: " + selectedSWModuleUpdateDetails
    );
    customConsole(
      "selectedSWModuleReplaceDetails: " + selectedSWModuleReplaceDetails
    );
  }, [selectedSWModuleUpdateDetails, selectedSWModuleReplaceDetails]);

  return (
    <>
      {/* Software module card */}
      <Card sx={styles.card}>
        <CardHeader
          sx={styles.cardHeader}
          title={
            <>
              <Typography
                textAlign={"start"}
                fontWeight={600}
                variant="subtitle1"
              >
                {heading}
              </Typography>
              <Typography
                textAlign={"start"}
                fontSize="13px"
                color="textSecondary"
              >
                {"Software Modules"}
              </Typography>
            </>
          }
          action={
            <Box
              sx={{
                pt: 1.5,
              }}
            >
              <Button
                sx={{ ...appTheme.commonBtnStyle }}
                startIcon={<AddIcon />}
                color="primary"
                size="small"
                variant="contained"
                onClick={() => setOpenSWModuleAddDialog(true)}
              >
                Add
              </Button>
            </Box>
          }
        />
        {/* Software modules list */}
        <CardContent sx={styles.cardContent}>
          {sWModulesList && sWModulesList?.length > 0 ? (
            sWModulesList?.map((item, index) => (
              <Box key={index} sx={styles.cardItems}>
                <Box sx={styles.cardItemsTxtContainer}>
                  <Typography variant="body2">
                    <strong>Name:</strong> {item?.name}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Description:</strong> {item?.description}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Version:</strong> {item?.version}
                  </Typography>
                </Box>
                <Box sx={styles.edRpIconsContainer}>
                  <Tooltip title="Update">
                    <IconButton
                      size="small"
                      color="info"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectedSWModuleUpdateDetails(item);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Replace">
                    <IconButton
                      size="small"
                      color="info"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectedSWModuleReplaceDetails(item);
                      }}
                    >
                      <TbReplace sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Logs">
                    <IconButton
                      size="small"
                      color="info"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSWLogsDialogOpen(item);
                      }}
                    >
                      <HistoryEduIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body1" sx={styles.noDataAvailableTxt}>
              No data available
            </Typography>
          )}
        </CardContent>
        <CardActions sx={styles.cardActions}>
          <Button
            startIcon={<CloseIcon />}
            color="error"
            size="small"
            variant="contained"
            onClick={() => {
              setIsEEComponentSelected(false);
              setSelectedEEComponentDetails(null);
            }}
            sx={{ ...appTheme.commonBtnStyle }}
          >
            Close
          </Button>
        </CardActions>
      </Card>

      <VlcSWModuleAddDialog
        openSWModuleAddDialog={openSWModuleAddDialog}
        setOpenSWModuleAddDialog={setOpenSWModuleAddDialog}
        handleSWModuleAdd={handleSWModuleAdd}
        vfOptionsList={vfOptionsList}
      />

      <VlcSWModuleUpdateDialog
        openSWModuleUpdateDialog={openSWModuleUpdateDialog}
        setOpenSWModuleUpdateDialog={setOpenSWModuleUpdateDialog}
        handleSWModuleUpdate={handleSWModuleUpdate}
        selectedSWModuleUpdateDetails={selectedSWModuleUpdateDetails}
        setSelectedSWModuleUpdateDetails={setSelectedSWModuleUpdateDetails}
      />

      <VlcSWModuleReplaceDialog
        openSWModuleReplaceDialog={openSWModuleReplaceDialog}
        setOpenSWModuleReplaceDialog={setOpenSWModuleReplaceDialog}
        handleSWModuleReplace={handleSWModuleReplace}
        selectedSWModuleReplaceDetails={selectedSWModuleReplaceDetails}
        setSelectedSWModuleReplaceDetails={setSelectedSWModuleReplaceDetails}
        vfOptionsList={vfOptionsList}
      />

      <CustomLogsDialog
        open={openSWModuleChangeLogsDialog}
        handleClose={handleSWLogsDialogClose}
        dialogTitle={"Change Logs"}
        dialogContent={swChangeLogsList}
        data={swLogsDialogData}
        getLogsData={handleGetSWModuleChangeLogs}
      />
    </>
  );
}

export default VlcSWModules;

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 26-07-2024
 * @description : Software module add dialog.
 * @param : openSWModuleAddDialog, setOpenSWModuleAddDialog, handleSWModuleAdd, vfOptionsList,
 * @return : The rendered software module add dialog.
 */
function VlcSWModuleAddDialog({
  openSWModuleAddDialog,
  setOpenSWModuleAddDialog,
  handleSWModuleAdd,
  vfOptionsList,
}) {
  /* Add SW Modules Fields */
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [summary, setSummary] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const [selectedVfIds, setSelectedVFIds] = useState([]);

  /* -------------- UseEffects -------------- */

  /* -------------- Add SW Modules -------------- */

  const clearAddSWModuleFields = () => {
    setName("");
    setNameError("");
    setVersion("");
    setVersionError("");
    setDescription("");
    setDescriptionError("");
    setSummary("");
    setSummaryError("");
    setSelectedVFIds([]);
  };

  const isAddSWModuleValid = () => {
    return (
      name?.trim() !== "" &&
      !nameError &&
      version?.trim() !== "" &&
      !versionError &&
      description?.trim() !== "" &&
      !descriptionError &&
      selectedVfIds?.length > 0 &&
      (summary?.trim()?.length === 0 || !summaryError)
    );
  };

  const handleCloseSWModuleAdd = () => {
    setOpenSWModuleAddDialog(false);

    clearAddSWModuleFields();
  };

  const handleAddSWModule = () => {
    handleSWModuleAdd({
      version: version,
      name: name,
      summary: summary,
      description: description,
      vf_ids: selectedVfIds,
    });

    handleCloseSWModuleAdd();
  };

  return (
    <CustomAddDialog
      open={openSWModuleAddDialog}
      onClose={handleCloseSWModuleAdd}
      dialogTitle={"Add SW Module"}
      dialogContent={
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Name*"}
            placeholder={"Name*"}
            type={"text"}
            fldType={"text"}
            value={name}
            setValue={setName}
            valueError={nameError}
            setValueError={setNameError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Version*"}
            placeholder={"Version*"}
            type={"text"}
            fldType={"textR"}
            value={version}
            setValue={setVersion}
            valueError={versionError}
            setValueError={setVersionError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Description*"}
            placeholder={"Description*"}
            type={"text"}
            fldType={"text"}
            value={description}
            setValue={setDescription}
            valueError={descriptionError}
            setValueError={setDescriptionError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Summary"}
            placeholder={"Summary"}
            type={"text"}
            fldType={"textO"}
            value={summary}
            setValue={setSummary}
            valueError={summaryError}
            setValueError={setSummaryError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <MultiSelectTextFieldN
            required
            mutiple
            size={"small"}
            label={"Select Vehicle Functions*"}
            optionsList={vfOptionsList}
            selectedValues={selectedVfIds}
            setSelectedValues={setSelectedVFIds}
            sltFldStyle={styles.customTextFieldN}
          />
        </Stack>
      }
      onConfirm={() => {
        handleAddSWModule({});
        clearAddSWModuleFields();
      }}
      onDisabled={!isAddSWModuleValid()}
    />
  );
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 26-07-2024
 * @description : Software module update dialog.
 * @param : openSWModuleUpdateDialog, setOpenSWModuleUpdateDialog, handleSWModuleUpdate, selectedSWModuleUpdateDetails, setSelectedSWModuleUpdateDetails,
 * @return : The rendered software module update dialog.
 */
function VlcSWModuleUpdateDialog({
  openSWModuleUpdateDialog,
  setOpenSWModuleUpdateDialog,
  handleSWModuleUpdate,
  selectedSWModuleUpdateDetails,
  setSelectedSWModuleUpdateDetails,
}) {
  /* Update SW Modules Fields */
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");

  useEffect(() => {
    if (openSWModuleUpdateDialog == true && selectedSWModuleUpdateDetails) {
      setVersion(selectedSWModuleUpdateDetails?.version || "");
    }
  }, [openSWModuleUpdateDialog, selectedSWModuleUpdateDetails]);

  /* -------------- Update SW Module -------------- */

  const clearUpdateSWModuleFields = () => {
    setVersion("");
    setVersionError("");
  };

  const isUpdateSWModuleValid = () => {
    return (
      !versionError &&
      version?.trim() !== selectedSWModuleUpdateDetails?.version
    );
  };

  const handleCloseSWModuleUpdate = () => {
    setOpenSWModuleUpdateDialog(false);

    clearUpdateSWModuleFields();

    setSelectedSWModuleUpdateDetails(null);
  };

  const handleUpdateSWModule = ({}) => {
    if (
      selectedSWModuleUpdateDetails &&
      selectedSWModuleUpdateDetails?.swm_id
    ) {
      handleSWModuleUpdate({
        swm_id: Number(selectedSWModuleUpdateDetails?.swm_id),
        version: version,
      });

      handleCloseSWModuleUpdate();
    }
  };

  return (
    <CustomUpdateDialog
      open={openSWModuleUpdateDialog}
      onClose={handleCloseSWModuleUpdate}
      dialogTitle={"Update SW Module"}
      dialogContent={
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Version*"}
            placeholder={"Version*"}
            type={"text"}
            fldType={"textR"}
            value={version}
            setValue={setVersion}
            valueError={versionError}
            setValueError={setVersionError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
        </Stack>
      }
      onConfirm={() => {
        handleUpdateSWModule({});
        clearUpdateSWModuleFields();
      }}
      onDisabled={!isUpdateSWModuleValid()}
    />
  );
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 26-07-2024
 * @description : Software module replace dialog.
 * @param : openSWModuleReplaceDialog, setOpenSWModuleReplaceDialog, handleSWModuleReplace, selectedSWModuleReplaceDetails, setSelectedSWModuleReplaceDetails, vfOptionsList,
 * @return : The rendered software module replace dialog.
 */
function VlcSWModuleReplaceDialog({
  openSWModuleReplaceDialog,
  setOpenSWModuleReplaceDialog,
  handleSWModuleReplace,
  selectedSWModuleReplaceDetails,
  setSelectedSWModuleReplaceDetails,
  vfOptionsList,
}) {
  /* Replace SW Modules Fields */
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [summary, setSummary] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const [selectedVfIds, setSelectedVfIds] = useState([]);

  /* -------------- UseEffects -------------- */

  useEffect(() => {
    if (openSWModuleReplaceDialog == true && selectedSWModuleReplaceDetails) {
      setName(selectedSWModuleReplaceDetails?.name || "");
      setVersion(selectedSWModuleReplaceDetails?.version || "");
      setDescription(selectedSWModuleReplaceDetails?.description || "");
      setSummary(selectedSWModuleReplaceDetails?.summary || "");
      setSelectedVfIds(
        (selectedSWModuleReplaceDetails?.vf_ids || [])
          .map((vfId) => Number(vfId))
          .filter((vfId) => !isNaN(vfId))
      );
    }
  }, [openSWModuleReplaceDialog, selectedSWModuleReplaceDetails]);

  /* -------------- Replace SW Module -------------- */

  const clearReplaceSWModuleFields = () => {
    setName("");
    setNameError("");
    setVersion("");
    setVersionError("");
    setDescription("");
    setDescriptionError("");
    setSummary("");
    setSummaryError("");
    setSelectedVfIds([]);
  };

  const isReplaceSWModuleValid = () => {
    return (
      !nameError &&
      !versionError &&
      !descriptionError &&
      selectedVfIds?.length > 0 &&
      (summary?.trim().length === 0 || !summaryError) &&
      (name?.trim() !== selectedSWModuleReplaceDetails?.name ||
        version?.trim() !== selectedSWModuleReplaceDetails?.version ||
        description?.trim() !== selectedSWModuleReplaceDetails?.description ||
        summary?.trim() !== selectedSWModuleReplaceDetails?.summary ||
        selectedVfIds.length !== selectedSWModuleReplaceDetails?.vf_ids.length)
    );
  };

  const handleCloseSWModuleReplace = () => {
    setOpenSWModuleReplaceDialog(false);

    clearReplaceSWModuleFields();

    setSelectedSWModuleReplaceDetails(null);
  };

  const handleReplaceSWModule = ({}) => {
    if (
      selectedSWModuleReplaceDetails &&
      selectedSWModuleReplaceDetails?.swm_id
    ) {
      handleSWModuleReplace({
        swm_id: Number(selectedSWModuleReplaceDetails?.swm_id),
        version: version,
        name: name,
        summary: summary,
        description: description,
        vf_ids: selectedVfIds,
      });

      handleCloseSWModuleReplace();
    }
  };

  return (
    <CustomReplaceDialog
      open={openSWModuleReplaceDialog}
      onClose={handleCloseSWModuleReplace}
      dialogTitle={"Replace SW Module"}
      dialogContent={
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Name*"}
            placeholder={"Name*"}
            type={"text"}
            fldType={"text"}
            value={name}
            setValue={setName}
            valueError={nameError}
            setValueError={setNameError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Version*"}
            placeholder={"Version*"}
            type={"text"}
            fldType={"textR"}
            value={version}
            setValue={setVersion}
            valueError={versionError}
            setValueError={setVersionError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Description*"}
            placeholder={"Description*"}
            type={"text"}
            fldType={"text"}
            value={description}
            setValue={setDescription}
            valueError={descriptionError}
            setValueError={setDescriptionError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Summary"}
            placeholder={"Summary"}
            type={"text"}
            fldType={"textO"}
            value={summary}
            setValue={setSummary}
            valueError={summaryError}
            setValueError={setSummaryError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <MultiSelectTextFieldN
            required
            mutiple
            size={"small"}
            label={"Select Vehicle Functions"}
            optionsList={vfOptionsList}
            selectedValues={selectedVfIds}
            setSelectedValues={setSelectedVfIds}
            sltFldStyle={styles.customTextFieldN}
          />
        </Stack>
      }
      onConfirm={() => {
        handleReplaceSWModule({});
        clearReplaceSWModuleFields();
      }}
      onDisabled={!isReplaceSWModuleValid()}
    />
  );
}

// Styles
const styles = {
  card: {
    mt: 1.3,
    mx: 2,
    width: "370px",
    boxShadow: 12,
  },
  cardHeader: {
    py: 1,
    backgroundColor: "lightgrey",
    borderBottom: "1px solid lightgrey",
  },
  cardContent: {
    height: "360px",
    overflowY: "auto",
  },
  cardItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 1.5,
    pl: 1.4,
    pr: 1,
    backgroundColor: "background.paper",
    borderRadius: 1,
    boxShadow: 4,
    border: "1px solid lightgrey",
    // cursor: "pointer",
    "&:hover": {
      // backgroundColor: "lightgrey",
    },
  },
  cardItemsTxtContainer: {
    flexGrow: 1,
    textAlign: "start",
    my: 1,
  },
  noDataAvailableTxt: {
    textAlign: "center",
    mt: 2,
  },
  customTextFieldN: {
    width: "270px",
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid lightgrey",
  },
  edRpIconsContainer: {
    display: "flex",
    flexDirection: "column",
  },
};
