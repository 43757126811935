/**
 * @author : Abdul Rahim M - abdul@au79consulting.com
 * @Date : 05-09-2024
 * @description : Component to add, update and replace ee component.
 */

import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import SoftwareModuleTables from "./SoftwareModuleTables";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions";
import softwareIntersectionAPI from "../../redux/actions/softwareIntersectionActions";

function EEComponentsTable({
  selectedEEMainId,
  selectedProjectId,
  selectedMainSWModuleDetails,
  eeMainLinkedOptionsList,
  handleSWModuleUnLink,
  handleSWModuleLink,
  softwareIntersectionDetails,
}) {
  const dispatch = useDispatch();

  const [filteredEE, setFilteredEE] = useState({});

  useEffect(() => {
    // console.log(
    //   "softwareIntersectionDetailsfdfheihvkj",
    //   softwareIntersectionDetails
    // );
  }, [softwareIntersectionDetails]);

  useEffect(() => {
    const filteredList = eeMainLinkedOptionsList?.find(
      (data) => data?.value === selectedEEMainId
    );
    // console.log("filteredList", filteredList);
    setFilteredEE(filteredList);
  }, [selectedEEMainId]);

  // console.log("selectedEEMainId", selectedEEMainId);

  return (
    <div>
      {/* <Paper
        elevation={12}
        sx={{ mt: "40px", paddingLeft: "20px", paddingRight: "20px" }}
      >
        {filteredEE && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              boxShadow: 2,
              mt: "20px",
              padding: "10px",
            }}
          >
            <Paper sx={{ display: "flex", padding: "10px" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Selected EE Component Name
              </Typography>
              <strong>:</strong>
              <Typography>{filteredEE?.label}</Typography>
            </Paper>
          </Box>
        )}

        <TableContainer
          component={Paper}
          sx={{ mt: "10px", maxHeight: "400px", overflowY: "auto" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography sx={{ fontWeight: "bold" }}>S.No</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Software Modules
                  </Typography>
                </TableCell>
                <TableCell colSpan={3}>
                  <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
                    Linked Software Modules
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell />

                <TableCell colSpan={5}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold", mr: "200px" }}>
                      Software Module
                    </Typography>
                    <Typography sx={{ fontWeight: "bold", mr: "200px" }}>
                      EE Component
                    </Typography>
                    <Typography sx={{ fontWeight: "bold", mr: "200px" }}>
                      Zone
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {softwareIntersectionDetails?.length > 0 ? (
                softwareIntersectionDetails?.map((module, idx) => (
                  <React.Fragment key={module?.swm_id}>
                    <TableRow>
                      <TableCell>{idx + 1}</TableCell>

                      <TableCell>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Name
                          </Typography>
                          <strong>:</strong>
                          <Typography>{module?.name}</Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Version
                          </Typography>
                          <strong>:</strong>
                          <Typography>{module?.version}</Typography>
                        </Box>
                      </TableCell>

                      {module?.linked_swm_details?.map(
                        (linkedModule, linkedIdx) => (
                          <>
                            <TableCell
                              sx={{
                                display: "flex",
                                justifyContent: "start",
                              }}
                            >
                              <Box>
                                <Box sx={{ display: "flex", mr: "200px" }}>
                                  <Typography
                                    sx={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Name
                                  </Typography>
                                  <strong>:</strong>
                                  <Typography
                                    sx={{
                                      textAlign: "start",
                                      whiteSpace: "normal",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {linkedModule?.name}
                                  </Typography>
                                </Box>

                                <Box sx={{ display: "flex" }}>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    Version
                                  </Typography>
                                  <strong>:</strong>
                                  <Typography
                                    sx={{
                                      textAlign: "start",
                                      whiteSpace: "normal",
                                      overflow: "visible",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {linkedModule?.version}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box>
                                <Box sx={{ display: "flex", mr: "200px" }}>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    Description
                                  </Typography>
                                  <strong>:</strong>
                                  <Typography sx={{ textAlign: "center" }}>
                                    {linkedModule?.ee_details?.description}
                                  </Typography>
                                </Box>
                                <Box sx={{ display: "flex" }}>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    Version
                                  </Typography>
                                  <strong>:</strong>
                                  <Typography sx={{ textAlign: "center" }}>
                                    {linkedModule?.ee_details?.version}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box sx={{ display: "flex" }}>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Name
                                </Typography>
                                <strong>:</strong>
                                <Typography sx={{ textAlign: "center" }}>
                                  {linkedModule.zone_details?.zone_name ||
                                    "N/A"}
                                </Typography>
                              </Box>
                            </TableCell>
                          </>
                        )
                      )}
                    </TableRow>
                  </React.Fragment>
                ))
              ) : (
                <Typography textAlign="center" sx={{ color: "red" }}>
                  Data Not Found
                </Typography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper> */}
      {/* <Box
        sx={{
          mt: "40px",
          paddingLeft: "20px",
          paddingRight: "20px",
          border: "1px solid black",
          borderRadius: "4px", // optional, for rounded corners
          boxShadow: "none", // remove shadow if you want a clean border look
        }}
      >
        {filteredEE && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              boxShadow: "none",
              mt: "20px",
              padding: "10px",
              borderBottom: "1px solid black",
            }}
          >
            <Box sx={{ display: "flex", padding: "10px" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Selected EE Component Name
              </Typography>
              <strong>:</strong>
              <Typography>{filteredEE?.label}</Typography>
            </Box>
          </Box>
        )}

        <Box
          sx={{
            mt: "10px",
            maxHeight: "400px",
            overflowY: "auto",
            border: "1px solid black",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography sx={{ fontWeight: "bold" }}>S.No</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Software Modules
                  </Typography>
                </TableCell>
                <TableCell colSpan={3}>
                  <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
                    Linked Software Modules
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell />

                <TableCell colSpan={5}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold", mr: "200px" }}>
                      Software Module
                    </Typography>
                    <Typography sx={{ fontWeight: "bold", mr: "200px" }}>
                      EE Component
                    </Typography>
                    <Typography sx={{ fontWeight: "bold", mr: "200px" }}>
                      Zone
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {softwareIntersectionDetails?.length > 0 ? (
                softwareIntersectionDetails?.map((module, idx) => (
                  <React.Fragment key={module?.swm_id}>
                    <TableRow>
                      <TableCell>{idx + 1}</TableCell>

                      <TableCell>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Name
                          </Typography>
                          <strong>:</strong>
                          <Typography>{module?.name}</Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Version
                          </Typography>
                          <strong>:</strong>
                          <Typography>{module?.version}</Typography>
                        </Box>
                      </TableCell>

                      {module?.linked_swm_details?.map(
                        (linkedModule, linkedIdx) => (
                          <TableCell
                            key={linkedModule?.id} // Assuming there's a unique identifier for each linked module
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              border: "1px solid black",
                            }}
                          >
                            <Box>
                              <Box sx={{ display: "flex", mr: "200px" }}>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Name
                                </Typography>
                                <strong>:</strong>
                                <Typography
                                  sx={{
                                    textAlign: "start",
                                    whiteSpace: "normal",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {linkedModule?.name}
                                </Typography>
                              </Box>

                              <Box sx={{ display: "flex" }}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  Version
                                </Typography>
                                <strong>:</strong>
                                <Typography
                                  sx={{
                                    textAlign: "start",
                                    whiteSpace: "normal",
                                    overflow: "visible",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {linkedModule?.version}
                                </Typography>
                              </Box>
                            </Box>

                            <Box>
                              <Box sx={{ display: "flex", mr: "200px" }}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  Description
                                </Typography>
                                <strong>:</strong>
                                <Typography sx={{ textAlign: "center" }}>
                                  {linkedModule?.ee_details?.description}
                                </Typography>
                              </Box>
                              <Box sx={{ display: "flex" }}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  Version
                                </Typography>
                                <strong>:</strong>
                                <Typography sx={{ textAlign: "center" }}>
                                  {linkedModule?.ee_details?.version}
                                </Typography>
                              </Box>
                            </Box>

                            <Box sx={{ display: "flex" }}>
                              <Typography
                                sx={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                Name
                              </Typography>
                              <strong>:</strong>
                              <Typography sx={{ textAlign: "center" }}>
                                {linkedModule.zone_details?.zone_name || "N/A"}
                              </Typography>
                            </Box>
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </React.Fragment>
                ))
              ) : (
                <Typography textAlign="center" sx={{ color: "red" }}>
                  Data Not Found
                </Typography>
              )}
            </TableBody>
          </Table>
        </Box>
      </Box> */}
      <Box
        sx={{
          mt: "40px",
          paddingLeft: "20px",
          paddingRight: "20px",
          border: "1px solid black",
          borderRadius: "4px", // optional, for rounded corners
          boxShadow: "none", // remove shadow if you want a clean border look
        }}
      >
        {filteredEE && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              boxShadow: "none",
              mt: "20px",
              padding: "10px",
              borderBottom: "1px solid black",
            }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "10px",
                border: "1px solid black",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                Selected EE Component Name
              </Typography>
              <strong>:</strong>
              <Typography>{filteredEE?.label}</Typography>
            </Box>
          </Box>
        )}

        <Box
          sx={{
            mt: "10px",
            maxHeight: "400px",
            overflowY: "auto",
            border: "1px solid black",
          }}
        >
          <Table sx={{ borderCollapse: "collapse" }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ border: "1px solid black" }}>
                  <Typography sx={{ fontWeight: "bold" }}>S.No</Typography>
                </TableCell>
                <TableCell sx={{ border: "1px solid black" }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Software Modules
                  </Typography>
                </TableCell>
                <TableCell colSpan={3} sx={{ border: "1px solid black" }}>
                  <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
                    Linked Software Modules
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "1px solid black" }} />
                <TableCell sx={{ border: "1px solid black" }} />

                <TableCell colSpan={5} sx={{ border: "1px solid black" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold", mr: "200px" }}>
                      Software Module
                    </Typography>
                    <Typography sx={{ fontWeight: "bold", mr: "200px" }}>
                      EE Component
                    </Typography>
                    <Typography sx={{ fontWeight: "bold", mr: "200px" }}>
                      Zone
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {softwareIntersectionDetails?.length > 0 ? (
                softwareIntersectionDetails?.map((module, idx) => (
                  <React.Fragment key={module?.swm_id}>
                    <TableRow>
                      <TableCell sx={{ border: "1px solid black" }}>
                        {idx + 1}
                      </TableCell>

                      <TableCell
                        sx={{ border: "1px solid black", width: "100px" }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Name
                          </Typography>
                          <strong>:</strong>
                          <Typography>{module?.name}</Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Version
                          </Typography>
                          <strong>:</strong>
                          <Typography>{module?.version}</Typography>
                        </Box>
                      </TableCell>

                      {module?.linked_swm_details?.map(
                        (linkedModule, linkedIdx) => (
                          <TableCell
                            key={linkedModule?.id} // Assuming there's a unique identifier for each linked module
                            sx={{
                              border: "1px solid black",
                              display: "flex",
                              maxHeight: "100px",
                              overflowY: "auto",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box sx={{ mb: "5px" }}>
                              <Box sx={{ display: "flex", mb: "2px" }}>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Name
                                </Typography>
                                <strong>:</strong>
                                <Typography
                                  sx={{
                                    textAlign: "start",
                                    whiteSpace: "normal",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {linkedModule?.name}
                                </Typography>
                              </Box>

                              <Box sx={{ display: "flex" }}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  Version
                                </Typography>
                                <strong>:</strong>
                                <Typography
                                  sx={{
                                    textAlign: "start",
                                    whiteSpace: "normal",
                                    overflow: "visible",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {linkedModule?.version}
                                </Typography>
                              </Box>
                            </Box>

                            <Box sx={{ mb: "5px" }}>
                              <Box sx={{ display: "flex", mb: "2px" }}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  Description
                                </Typography>
                                <strong>:</strong>
                                <Typography sx={{ textAlign: "center" }}>
                                  {linkedModule?.ee_details?.description}
                                </Typography>
                              </Box>
                              <Box sx={{ display: "flex" }}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  Version
                                </Typography>
                                <strong>:</strong>
                                <Typography sx={{ textAlign: "center" }}>
                                  {linkedModule?.ee_details?.version}
                                </Typography>
                              </Box>
                            </Box>

                            <Box>
                              <Box sx={{ display: "flex" }}>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Name
                                </Typography>
                                <strong>:</strong>
                                <Typography sx={{ textAlign: "center" }}>
                                  {linkedModule.zone_details?.zone_name ||
                                    "N/A"}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </React.Fragment>
                ))
              ) : (
                <Typography textAlign="center" sx={{ color: "red" }}>
                  Data Not Found
                </Typography>
              )}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </div>
  );
}

export default EEComponentsTable;
